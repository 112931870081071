    #technologies {
        /* position: relative; */
        padding: 20px 0px 50px 0px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        background: transparent;
        min-height: 100vh;
        height: max-content;
        margin: 0px;
        clear: both;
        z-index: 0;
        /* overflow: visible; */
    }
    
    .technologies-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 99%;
        max-width: 975px;
        height: auto;
        padding: 0.5em 0 1em 0;
        /* margin: 0px 5px 0px 5px; */
        background-color: rgba(8, 31, 48, 0.6);
        border: 1px ridge #C0F5FA;
        border-radius: 40px;
        box-sizing: border-box;
        font-family: Helvetica;
        font-size: 1em;
        text-align: center;
        z-index: 500;
        animation: 2s fadeIn;
        transition: 2s all;
    }
    
    .technologies-box-background-image {
        position: absolute;
        background-image: url('../../assets/images/code.jpg');
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        filter: blur(2px);
        opacity: 0.2;
    }
    
    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    
    .technologies-headings {
        z-index: 100;
        width: auto;
        color: #F9F9F9;
        font-family: "Helvetica";
        font-size: 48px;
        border-bottom: 1px solid rgb(85, 238, 255);
        text-align: center;
        text-shadow: 2px -0px rgba(0, 0, 0, 0.3);
        margin: 0 auto 0 auto;
        padding-bottom: 0.25em;
        /* -webkit-text-stroke: 3px #000000; */
    }
    
    .technology-instructions {
        z-index: 1000;
        width: 80%;
        font-family: "Helvetica";
        padding: 0 5% 0 5%;
        font-size: 40px;
        text-shadow: 2px -0px rgba(0, 0, 0, 0.3);
        text-align: center;
        margin-top: .5em;
        margin-bottom: 0.5em;
        color: #F9F9F9;
    }
    
    .technologies-display {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 650px;
        height: auto;
        margin: 0px auto 0px auto;
        padding: 10px 0px 10px 0px;
        flex-wrap: wrap;
        box-sizing: border-box;
        overflow-x: hidden;
    }
    
    .languagePlatformIconSVG {
        width: 120px;
        margin: 10px;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }
    
    .flip-card {
        margin: 5px;
        padding: 0px;
        width: 135px;
        height: 135px;
        /* background-color: rgba(0, 0, 0, 0); */
        border-radius: 10px;
        overflow: hidden;
        -webkit-box-shadow: 2px 2px 5px 1px #D4F1F9;
        /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow: 1px 2px 5px 1px #D4F1F9;
        /* Firefox 3.5 - 3.6 */
        box-shadow: 1px 1px 3px 2px #D4F1F9;
        /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
        transition: transform 0.8s;
        transform-style: preserve-3d;
        border: 2px solid #CDEDFA;
        perspective: 1000px;
        /* Remove this if you don't want the 3D effect */
    }
    /* Do an horizontal flip when you move the mouse over the flip box container */
    
    .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
    }
    /* This container is needed to position the front and back side */
    
    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }
    /* Position the front and back side */
    
    .flip-card-front,
    .flip-card-back {
        border-radius: 10px;
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }
    
    .onlyText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
    }
    
    .onlyText a {
        text-decoration: none;
    }
    
    .onlyText a:link,
    .onlyText a:visited {
        text-decoration: none;
        color: #000
    }
    
    .onlyText a:hover {
        color: #000;
    }
    /* Style the front side (fallback if image is missing) */
    
    .flip-card-front {
        background-color: rgba(117, 174, 220, 0.85)
    }
    /* Style the back side */
    
    .flip-card-back {
        background-color: dodgerblue;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        transform: rotateY(180deg);
    }
    
    @media (max-width: 950px) {
        #technologies {
            padding-top: 50px;
        }
        .technologies-headings {
            padding-top: 20px;
            width: 90%;
            font-family: "Helvetica";
            text-align: center;
            margin: 0 auto 20px auto;
        }
        .technologies-display {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 90%;
            margin: auto;
            padding: 10px 0 10px 0;
            flex-wrap: wrap;
            box-sizing: border-box;
        }
    }
    
    @media (max-width: 500px) {
        #technologies {
            padding: 10px 0px 50px 0px;
        }
        .technologies-headings {
            font-size: 2em;
        }
    }
    
    @media (max-width:450px) {
        .technology-instructions {
            font-size: 1.5em;
            margin: 0px;
        }
    }
    
    @media (min-width: 850px) and (min-height: 800px) {
        .technologies-box {
            justify-content: center;
        }
    }
    
    @media (min-width: 951px) {
        .technologies-display {
            width: 80%;
            max-width: 950px;
        }
    }