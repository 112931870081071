.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    margin: 0px;
    overflow-x: hidden;
}

.contact-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
    max-width: 975px;
    height: auto;
    padding: 1em 0px 1em 0px;
    background-color: rgba(8, 31, 48, 0.6);
    border: 1px ridge #C0F5FA;
    border-radius: 40px;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 1em;
    text-align: center;
    z-index: 500;
    animation: 2s fadeIn;
    transition: 2s all;
    overflow: hidden;
}

.contact-form-container {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    box-sizing: border-box;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.contact-form-heading {
    width: auto;
    max-width: 90%;
    /* color: rgba(255, 255, 255, 1); */
    color: #F9F9F9;
    font-family: "Helvetica";
    /* padding-bottom: .25em; */
    padding-top: .25em;
    font-size: 2.1em;
    border-bottom: 1px solid rgb(85, 238, 255);
    /* border-top: 1px solid #F9F9F9; */
    text-align: center;
    text-shadow: 2px 0px rgba(0, 0, 0, 0.3);
}

.contact-form-instructions {
    font-family: "Helvetica";
    font-size: 2em;
    width: 80%;
    /* color: rgba(255, 255, 255, 1); */
    color: #F9F9F9;
    text-align: center;
    text-shadow: 2px 0px rgba(0, 0, 0, 0.3);
    margin: 0.5em 0 0.5em 0;
}

.contact-button {
    height: 50px;
    width: 250px;
    border: 1px solid rgb(85, 238, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 1s all;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.4);
}

.contact-button:hover {
    background-color: aqua;
    cursor: pointer;
}

.contact-button-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    text-decoration: none;
    font-size: 1.5em;
    /* color: rgba(255, 255, 255, 1); */
    color: rgb(85, 238, 255);
}

.contact-button-text:hover {
    color: #232323;
    font-weight: 600;
}

.icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 300px;
    height: 50px;
    margin-top: 50px;
}

.icon-container:hover {
    cursor: pointer
}

.icon-container a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    /* color: rgba(255, 255, 255, 1); */
    color: #F9F9F9;
    font-size: 1em;
    font-weight: bold;
}

.icon-text {
    margin-top: 5px;
}

.bottom-strip {
    position: relative;
    left: 0;
    bottom: 30px;
    width: 100%;
    height: 30px;
    background-color: rgba(13, 2, 33, 1);
}

@media(max-width: 700px) {
    .contact-form-container {
        width: 100%;
    }
    .contact-form-instructions {
        font-size: 1.5em;
    }
    .icon-container {
        margin-top: 30px;
    }
}

@media(max-width: 500px) {
    .contact-heading {
        font-size: 2em;
    }
}

@media(max-width: 450px) {
    .contact-instructions {
        font-size: 1.5em;
    }
}

@media (max-width: 321px) {
    .contact-form-instructions {
        font-size: 1.25em;
    }
    .icon-container {
        margin-top: 40px;
    }
}


/*  */

.contact-welcome-box-text-special {
    color: #75F4F4;
}