.navBurger {
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 5000;
    margin: 0px;
    padding-top: 15px;
    padding-right: 15px;
}

#navBurger-icon {
    margin: 0px;
    padding: 5px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 2.5em;
    color: rgba(2, 0, 36, 1);
}

.scrollingNavBurgerIcon {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    /* background-color: #3895D3; */
    background-color: #75F4F4;
     border-radius: 50%;
}