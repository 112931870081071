.App {
    margin: 0px;
    padding: 0px;
    top: 0;
    left: 0;
    font-family: "Helvetica";
    overflow: hidden;
    background-color: rgba(2, 0, 36, 1);
}

 ::-webkit-scrollbar {
    height: 0px;
    /* remove scrollbar space */
    width: 0px;
    background: transparent;
    /* background-color: #aaa; */
    /* optional: just make scrollbar invisible */
}