   #slide {
       position: absolute;
       right: -500px;
       top: 0px;
       width: 100%;
       height: 100px;
       /* background: blue; */
       -webkit-animation: slide 0.5s forwards;
       -webkit-animation-delay: 2s;
       animation: slide 0.5s forwards;
       animation-delay: 0.05s;
   }
   
   .slide-out {
       right: -500px;
       top: 0px;
       -webkit-animation: slide 0.5s forwards;
       -webkit-animation-delay: 2s;
       animation: slide 0.5s forwards;
       animation-delay: 0.05s;
   }
   
   .sideDrawer {
       display: flex;
       flex-flow: column;
       justify-content: flex-start;
       align-items: flex-end;
       position: absolute;
       right: 0;
       top: 0;
       height: 100vh;
       min-width: 250px;
       width: 250px;
       max-width: 500px;
       /* background-color: #022F40; */
       /* background-color: #2274A5; */
       /* background-color: rgba(0,0,0,0.4); */
       background-color: rgba(8, 31, 48, 0.94);
       /* background: radial-gradient( circle at top right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 60%, rgba(0, 0, 0, 0) 61%); */
       font-family: "Helvetica";
       border-left: 1px ridge #75F4F4;
   }
   
   .sideDrawer:hover {
       cursor: default;
   }
   
   .sideDrawerHeader {
       display: flex;
       flex-flow: column;
       justify-content: center;
       align-items: flex-end;
       padding-top: 50px;
       width: 100%;
       height: 100px;
   }
   
   .sideDrawerHeader p {
       padding-right: 30px;
       color: rgba(255, 255, 255, 0.9);
       /* color: rgba(117, 174, 220, 1); */
       /* color: #18426b; */
       font-family: "Helvetica";
       font-weight: bold;
       font-size: 32px;
       margin-bottom: 0px;
   }
   
   .sideDrawer-link {
       /* color: rgba(0, 0, 0, 0.87); */
       /* color: #18426b; */
       /* color: #5299D3; */
       color: rgba(117, 174, 220, 1);
       /* color: rgba(0, 0, 0, 0, 0.7); */
       font-family: "Helvetica";
       /* color: #18426b; */
       font-size: 20px;
       display: flex;
       justify-content: flex-end;
       align-items: center;
       width: auto;
       height: 36px;
       margin: 0px 0 0 0;
       padding: 5px 30px 5px 5px;
   }
   
   .sideDrawer-link:hover {
       font-weight: bold;
       color: #18426b;
       background-color: rgba(49, 225, 231, 0.26);
       background: linear-gradient( to left, rgba(0, 255, 255, 0.959), rgba(49, 225, 231, 0.26) 80%, transparent 100%);
   }
   
   .sideDrawer-icon-container {
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: flex-end;
       /* flex-wrap: wrap; */
       width: 100%;
       height: auto;
       margin-top: 0px;
       padding: 0px 0px 0px 0px;
       /* border-top: 1px solid lightgrey; */
       /* background-color: #18426b; */
       /* background: linear-gradient( to left, #18426b, #18426b 40%, transparent 100%); */
   }
   
   .sideDrawer-icon-container:hover {
       /* cursor: pointer */
   }
   
   .sideDrawer-icon-container a i {
       font-size: 2em;
       /* display: flex;
       flex-direction: column; */
       /* color: #33A9DC; */
       /* color: #5299D3; */
       /* color: #18426b; */
       color: rgba(117, 174, 220, 1);
       padding: 8px;
       border-radius: 10px;
       width: 33px;
       height: 33px;
       text-align: center;
       margin-top: 5px;
       text-decoration: none;
   }
   
   .sideDrawer-icon-container a i:hover {
       font-size: 2em;
       color: #18426b;
       background: linear-gradient(to left, rgba(0, 255, 255, 0.959), rgba(0, 255, 255, 0.959) 66%, transparent);
   }
   
   .nav-item-link {
       color: rgba(0, 0, 0, 0.87);
       width: 90%;
       margin: 5px 10px 5px 5px;
       cursor: pointer;
   }
   
   .icon-container-row {
       display: flex;
       flex-flow: row;
       align-items: center;
       justify-content: space-evenly;
       width: 100%;
   }
   
   .linkWrapper {
       width: 70px;
   }
   
   .linkWrapper a {
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       text-decoration: none;
       color: rgba(117, 174, 220, 1);
       margin: 0px 5px 0px 5px;
   }
   
   .icon-text {
       margin: 0;
       font-weight: bold;
   }
   
   .nav-item-link:hover {
       color: rgba(0, 0, 0, 0.87);
   }
   
   .active {
       font-weight: bold;
       /* box-shadow: -2px 2px 2px 1px #C0F5FA; */
       /* -webkit-box-shadow: -3px 3px 1px 0px #C0F5FA; */
       /* -moz-box-shadow: 3px 3px 5px 6px #C0F5FA;
       box-shadow: 3px 3px 5px 6px #C0F5FA; */
   }
   
   .active p {
       font-weight: bold;
       color: #75F4F4;
       /* color: #18426b; */
       /* color: rgba(0, 0, 0, 0.87); */
       /* text-shadow: 1px 1px #C0F5FA; */
   }
   
   .sideDrawerShadow {
       -webkit-box-shadow: 3px 3px 5px 6px #C0F5FA;
       /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
       -moz-box-shadow: 3px 3px 5px 6px #C0F5FA;
       /* Firefox 3.5 - 3.6 */
       box-shadow: 3px 3px 5px 6px #C0F5FA;
       /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
   }
   /* Slide In */
   
   @-webkit-keyframes slide {
       100% {
           right: 0;
           top: 0;
       }
   }
   
   @keyframes slide {
       100% {
           right: 0;
           top: 0;
       }
   }