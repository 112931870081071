#footer {
    position: relative;
    height: 50px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 10px 10px;
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    color: #1abc9c;
}

#footer span {
    font-size: 20px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.footer-box-background-image {
    position: absolute;
    background-image: url('../../assets/images/code.jpg');
    background-size: cover;
    width: 100%;
    height: 100px;
    filter: blur(10px);
    opacity: 0.3;
}