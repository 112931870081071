        .form-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 5000;
            top: 0;
            left: 0;
            transition: 1s all;
            opacity: 1;
        }
        
        .form-show {
            opacity: 1;
            pointer-events: none;
        }
        
        .start-welcome-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: auto;
            min-width: 300px;
            height: auto;
            padding: 0.5em;
            background-color: rgba(8, 31, 48, 0.6);
            border: 1px ridge #C0F5FA;
            border-radius: 40px;
            box-sizing: border-box;
            font-family: Helvetica;
            font-size: 72px;
            text-align: center;
            margin: auto;
            z-index: 500;
            animation: 2s fadeIn;
            transition: 2s all;
        }
        
        .form-background {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 6000;
            height: 100%;
            width: 100%;
            margin: 0px;
            padding: 0px;
            background-color: rgb(42, 42, 42, 0.5);
        }
        
        .form-container {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            width: auto;
            height: auto;
            background-color: rgba(8, 31, 48, 0.95);
            border: 1px ridge #C0F5FA;
            position: relative;
            overflow-y: hidden;
            padding: 25px;
            z-index: 7000;
            margin: auto;
            border-radius: 5px;
        }
        
        .form-container-heading {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            color: #C0F5FA;
            font-size: 30px;
            margin: 5px 0px 5px 0px;
        }
        
        form {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            height: auto;
        }
        
        #name,
        #email,
        #city,
        #country {
            font-family: "Helvetica";
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px ridge #022F40;
            box-sizing: border-box;
            width: 330px;
            height: 40px;
            margin: 10px auto 0px auto;
            padding-left: 1em;
            background-color: #F5F5F5;
            border-radius: 5px;
            transition: 1s all;
        }
        
        #message {
            font-family: "Helvetica";
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px ridge #022F40;
            box-sizing: border-box;
            width: 330px;
            height: 300px;
            margin: 1em auto 0px auto;
            padding: 1em;
            background-color: #F5F5F5;
            border-radius: 5px;
            transition: 1s all;
        }
        
        #submit {
            font-family: "Helvetica";
            font-size: 1.5em;
            color: #022F40;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
            box-sizing: border-box;
            width: 330px;
            height: 40px;
            margin: 10px auto 0px auto;
            padding-left: 1em;
            background-color: #C0F5FA;
            border-radius: 5px;
            transition: .25s all;
        }
        
        #submit:hover {
            background-color: #75F4F4;
            font-size: 1.5em;
            cursor: pointer;
        }
        
        .form-close-button {
            position: absolute;
            font-family: "Helvetica";
            display: flex;
            justify-content: center;
            align-items: center;
            top: 1px;
            right: 1px;
            background-color: transparent;
            background-color: rgba(8, 31, 48, 0.6);
            box-sizing: border-box;
            width: 57px;
            height: 57px;
            border-radius: 5px;
            transition: 1s all;
            cursor: pointer;
            color: #C0F5FA;
        }
        
        .form-close-button:hover {
            cursor: pointer;
            background-color: #C0F5FA;
            color: rgba(8, 31, 48, 1);
        }
        
        .form-close-button-text {
            font-size: 42px;
        }
        
        @media(min-width:951px) {
            #name,
            #email,
            #city,
            #country,
            #message {
                width: 600px;
                font-size: 1.3em;
            }
        }
        
        @media(max-width:950px) {
            #name,
            #email,
            #city,
            #country,
            #message {
                width: 500px;
            }
        }
        
        @media(max-width: 600px) {
            .form-container {
                width: 99%;
                height: 90%;
            }
            .form-container h2 {
                margin: 5px 0px 5px 0px;
            }
            #name,
            #email,
            #city,
            #country,
            #message {
                width: 330px;
            }
            #submit {
                margin-top: 12px;
            }
            .form-info {
                height: auto;
                font-size: .75em;
            }
        }