.parallax {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    margin: 0px;
    overflow: hidden;
    position: fixed;
    background-color: #252934;
    background-image: url('../../assets/images/programming.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.particle-canvas {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    animation: 5s fadeIn;
    cursor: crosshair;
    opacity: 0.7;
}

.scrollInstruction {
    position: absolute;
    z-index: 3000;
    right: 0;
    margin-right: 15px;
    padding: 5px;
    width: 50px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 40px;
    font-size: 1.5em;
}

.scrollInstruction i {
    color: rgb(85, 238, 255);
    z-index: 4000;
}

.scrollInstruction i:hover {
    cursor: pointer;
}


/* Dimension Changes for different devices */

@media (max-width: 1200px) {
    .scrollInstruction {
        display: none;
    }
}

@media (max-width: 1330px) {
    .chart-wrapper {
        height: 300px;
    }
}