.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.map-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
    opacity: 0.91;
    border-radius: 1em;
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    background-color: #0e1626;
}

.top-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 2em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-color: #0e1626;
}