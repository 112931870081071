    .portfolio {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        margin: 0px;
        padding: 5px 0px 50px 0px;
        overflow-x: hidden;
        overflow-y: hidden;
        background: transparent;
    }
    
    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    
    .portfolio-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 99%;
        min-width: 300px;
        max-width: 975px;
        height: auto;
        padding: 1.5em;
        /* margin: 10px; */
        /* margin: 0px 5px 0px 5px; */
        background-color: rgba(8, 31, 48, 0.6);
        border: 1px ridge #C0F5FA;
        border-radius: 40px;
        box-sizing: border-box;
        font-family: Helvetica;
        font-size: 1em;
        text-align: center;
        z-index: 500;
        animation: 2s fadeIn;
        transition: 2s all;
    }
    
    .portfolios-box-background-image {
        position: absolute;
        background-image: url('../../assets/images/code.jpg');
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        filter: blur(2px);
        opacity: 0.2;
    }
    
    .portfolio-heading {
        z-index: 1000;
        width: auto;
        color: #F9F9F9;
        font-family: "Helvetica";
        font-size: 48px;
        text-shadow: -2px 0px rgba(0, 0, 0, 0.3);
        padding-bottom: .25em;
        border-bottom: 1px solid rgb(85, 238, 255);
        text-align: center;
        margin: 0px 0px 15px 0px;
    }
    
    /* .portfolio-instructions {
        z-index: 1000;
        font-family: "Helvetica";
        padding: 0 5% 0 5%;
        font-size: 40px;
        text-shadow: -2px 0px rgba(0, 0, 0, 0.3);
        text-align: center;
        margin-top: .5em;
        margin-bottom: 0.5em;
        color: #F9F9F9;
    } */
    
    .portfolio-filter {
        z-index: 1000;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        /* min-width: 500px; */
        height: 50px;
        margin-bottom: 15px;
    }
    
    .shadow {}
    
    .portfolio-filter-item {
        z-index: 1000;
        margin: auto 5px 0 5px;
        font-family: "Helvetica";
        font-size: 1.5em;
        font-weight: "400";
        color: rgb(85, 238, 255);
        line-height: 50px;
        padding: 0px 15px 0px 15px;
        transition: .5s all;
        border: 1px solid rgb(85, 238, 255);
        border-radius: .25em;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0px 0px 5px 1px rgb(106, 140, 179);
        /* -moz-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow: 3px 3px 5px 6px #ccc; */
    }
    
    .portfolio-filter-item:hover {
        background-color: rgb(85, 238, 255);
        border: 2px solid rgba(0, 0, 0, 0.8);
        cursor: pointer;
        -webkit-box-shadow: 0px 1px 1px 1px rgb(106, 158, 179);
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
    }
    
    .filter-active {
        background-color: rgb(85, 238, 255);
        border: 2px solid rgba(0, 0, 0, 0.8);
        font-weight: bold;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
    }
    
    .portfolio-projects-wrapper {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 912px;
        height: 604px;
        box-sizing: content-box
    }
    
    .portfolio-project {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        width: 300px;
        height: 300px;
        box-sizing: border-box;
        margin-left: 1px;
        margin-right: 1px;
        margin-top: 1px;
        margin-bottom: 1px;
        border-radius: 10px;
    }
    
    .portfolio-project-image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 300px;
        height: 300px;
    }
    
    .portfolio-project-info {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        z-index: 2000;
        top: 0;
        left: 0;
        width: 300px;
        height: 300px;
        opacity: 0;
        background-color: transparent;
        box-sizing: border-box;
        transition: .25s all;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.72);
    }
    
    .portfolio-project-info:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
        /* color: rgb(85, 238, 255); */
    }
    
    .portfolio-project-info-top {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        padding: .25em;
        text-align: center;
        color: white;
    }
    
    .portfolio-project-name {
        font-size: 32px;
        /* color: #232323; */
        color: white;
        font-family: "Helvetica";
        margin: 0px;
        padding: 40px 0px 0px 5px;
    }
    
    .portfolio-project-technologies {
        font-size: 24px;
        padding: .25em;
        /* color: #232323; */
        color: white;
        font-family: "Helvetica";
    }
    
    .portfolio-project-info-bottom {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
    /* .portfolio-project-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 250px;
        padding: .25em;
        border: 1px solid #232323;
        border-radius: 5px;
        box-sizing: border-box;
        transition: .25s all;
        background-color: white;
    }
    
    .portfolio-project-button:hover {
        cursor: pointer;
        background-color: rgb(85, 238, 255);
        color: #232323;
        font-weight: bold;
        font-size: 1.1em;
    }
    
    .portfolio-project-button-text {
        font-size: 20px;
        height: 50px;
        line-height: 50px;
        width: 100%;
        text-align: center;
    } */
    
    .portfolio-project-button {
        height: 50px;
        width: 250px;
        border: 1px solid rgb(85, 238, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 1s all;
        border-radius: 25px;
        background-color: rgba(0, 0, 0, 0.4);
    }
    
    .portfolio-project-button:hover {
        background-color: aqua;
        cursor: pointer;
    }
    
    .portfolio-project-button-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100%;
        text-decoration: none;
        font-size: 1.5em;
        /* color: rgba(255, 255, 255, 1); */
        color: rgb(85, 238, 255);
    }
    
    .portfolio-project-button-text:hover {
        color: #232323;
        font-weight: 600;
    }
    
    @media(max-width: 950px) {
        .portfolio {
            height: auto;
        }
        .portfolio-projects-wrapper {
            position: relative;
            width: 620px;
            height: auto;
        }
        .portfolio-instructions {
            font-size: 32px;
        }
    }
    
    @media(max-width: 650px) {
        .portfolio-projects-wrapper {
            width: 300px;
            margin: auto
        }
    }
    
    @media(max-width: 500px) {
        .portfolio-heading {
            font-size: 2em;
        }
    }
    
    @media(max-width: 450px) {
        .portfolio-instructions {
            font-size: 1.5em;
        }
    }