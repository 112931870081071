body, html {
    margin: 0px;
    padding: 0px;
    top: 0;
    left: 0;
    height: 100vh;
}

    #technologies {
        /* position: relative; */
        padding: 20px 0px 50px 0px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: column;
                flex-flow: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        background: transparent;
        min-height: 100vh;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        margin: 0px;
        clear: both;
        z-index: 0;
        /* overflow: visible; */
    }
    
    .technologies-box {
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        width: 99%;
        max-width: 975px;
        height: auto;
        padding: 0.5em 0 1em 0;
        /* margin: 0px 5px 0px 5px; */
        background-color: rgba(8, 31, 48, 0.6);
        border: 1px ridge #C0F5FA;
        border-radius: 40px;
        box-sizing: border-box;
        font-family: Helvetica;
        font-size: 1em;
        text-align: center;
        z-index: 500;
        -webkit-animation: 2s fadeIn;
                animation: 2s fadeIn;
        transition: 2s all;
    }
    
    .technologies-box-background-image {
        position: absolute;
        background-image: url(/static/media/code.f2a1a633.jpg);
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        -webkit-filter: blur(2px);
                filter: blur(2px);
        opacity: 0.2;
    }
    
    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    
    .technologies-headings {
        z-index: 100;
        width: auto;
        color: #F9F9F9;
        font-family: "Helvetica";
        font-size: 48px;
        border-bottom: 1px solid rgb(85, 238, 255);
        text-align: center;
        text-shadow: 2px -0px rgba(0, 0, 0, 0.3);
        margin: 0 auto 0 auto;
        padding-bottom: 0.25em;
        /* -webkit-text-stroke: 3px #000000; */
    }
    
    .technology-instructions {
        z-index: 1000;
        width: 80%;
        font-family: "Helvetica";
        padding: 0 5% 0 5%;
        font-size: 40px;
        text-shadow: 2px -0px rgba(0, 0, 0, 0.3);
        text-align: center;
        margin-top: .5em;
        margin-bottom: 0.5em;
        color: #F9F9F9;
    }
    
    .technologies-display {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        width: 650px;
        height: auto;
        margin: 0px auto 0px auto;
        padding: 10px 0px 10px 0px;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        box-sizing: border-box;
        overflow-x: hidden;
    }
    
    .languagePlatformIconSVG {
        width: 120px;
        margin: 10px;
        transition: -webkit-transform 0.8s;
        transition: transform 0.8s;
        transition: transform 0.8s, -webkit-transform 0.8s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
    }
    
    .flip-card {
        margin: 5px;
        padding: 0px;
        width: 135px;
        height: 135px;
        /* background-color: rgba(0, 0, 0, 0); */
        border-radius: 10px;
        overflow: hidden;
        /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        /* Firefox 3.5 - 3.6 */
        box-shadow: 1px 1px 3px 2px #D4F1F9;
        /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
        transition: -webkit-transform 0.8s;
        transition: transform 0.8s;
        transition: transform 0.8s, -webkit-transform 0.8s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        border: 2px solid #CDEDFA;
        -webkit-perspective: 1000px;
                perspective: 1000px;
        /* Remove this if you don't want the 3D effect */
    }
    /* Do an horizontal flip when you move the mouse over the flip box container */
    
    .flip-card:hover .flip-card-inner {
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
    }
    /* This container is needed to position the front and back side */
    
    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: -webkit-transform 0.8s;
        transition: transform 0.8s;
        transition: transform 0.8s, -webkit-transform 0.8s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
    }
    /* Position the front and back side */
    
    .flip-card-front,
    .flip-card-back {
        border-radius: 10px;
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
    }
    
    .onlyText {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        color: white;
    }
    
    .onlyText a {
        text-decoration: none;
    }
    
    .onlyText a:link,
    .onlyText a:visited {
        text-decoration: none;
        color: #000
    }
    
    .onlyText a:hover {
        color: #000;
    }
    /* Style the front side (fallback if image is missing) */
    
    .flip-card-front {
        background-color: rgba(117, 174, 220, 0.85)
    }
    /* Style the back side */
    
    .flip-card-back {
        background-color: dodgerblue;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        color: white;
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
    }
    
    @media (max-width: 950px) {
        #technologies {
            padding-top: 50px;
        }
        .technologies-headings {
            padding-top: 20px;
            width: 90%;
            font-family: "Helvetica";
            text-align: center;
            margin: 0 auto 20px auto;
        }
        .technologies-display {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            width: 90%;
            margin: auto;
            padding: 10px 0 10px 0;
            -webkit-flex-wrap: wrap;
                    flex-wrap: wrap;
            box-sizing: border-box;
        }
    }
    
    @media (max-width: 500px) {
        #technologies {
            padding: 10px 0px 50px 0px;
        }
        .technologies-headings {
            font-size: 2em;
        }
    }
    
    @media (max-width:450px) {
        .technology-instructions {
            font-size: 1.5em;
            margin: 0px;
        }
    }
    
    @media (min-width: 850px) and (min-height: 800px) {
        .technologies-box {
            -webkit-justify-content: center;
                    justify-content: center;
        }
    }
    
    @media (min-width: 951px) {
        .technologies-display {
            width: 80%;
            max-width: 950px;
        }
    }
.modal-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.modal-background {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6000;
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: rgb(42, 42, 42, 0.5);
}

.model-box {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 99%;
    min-width: 300px;
    max-width: 975px;
    height: auto;
    padding: 1.5em;
    /* margin: 10px; */
    /* margin: 0px 5px 0px 5px; */
    background-color: rgba(8, 31, 48, 0.6);
    border: 1px ridge #C0F5FA;
    border-radius: 40px;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 1em;
    text-align: center;
    z-index: 500;
    -webkit-animation: 2s fadeIn;
            animation: 2s fadeIn;
    transition: 2s all;
}

.modal-container {
    width: 650px;
    height: 700px;
    /* border: 1px ridge #022F40; */
    border: 1px ridge #C0F5FA;
    /* background-color: #2274A5; */
    background-color: rgba(8, 31, 48, 0.9);
    color: #E7DFC6;
    position: relative;
    border-radius: 5px;
    overflow-y: hidden;
    padding: .25em;
    z-index: 7000;
    margin: auto;
}

.modal-very-top {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 55px;
    width: 100%;
}

.modal-top {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 50%;
    width: 100%;
}

.modal-image-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-bottom;
            align-items: flex-bottom;
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: rgba(8, 31, 48, 0.5);
    overflow-y: hidden;
    border-radius: 5px;
    padding: 0px 00px 0px 0px;
}


/* Carousel */

.slider-slide img {
    height: auto;
    max-height: 350px;
    width: auto!important;
    max-width: 100%!important;
    margin: auto;
}

.slider-control-bottomcenter {
    display: none;
}

.slider button {
    font-size: 1em;
    font-weight: bold;
    margin: 0px 5px 0px 5px;
}


/**/

.modal-info {
    padding: 0px 20px 0px 20px;
    font-size: 20px;
}

.modal-info-project-name {
    font-family: "Helvetica";
    font-size: 1.9em;
}

.modal-info-project-summary {
    font-family: "Helvetica"
}

.modal-info-project-link {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: 1px solid black;
    box-sizing: border-box;
    width: 330px;
    font-size: 20px;
    height: 40px;
    margin: auto;
    background-color: #F5F5F5;
    border-radius: 5px;
    transition: 1s all;
}

.modal-info-project-link a {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    color: #022F40;
    height: 40px;
    width: 330px;
    text-align: center;
}

.modal-info-project-link:hover {
    cursor: pointer;
    background-color: aqua
}

.modal-close-button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 20px;
    border: 1px solid black;
    color: #C0F5FA;
    background-color: transparent;
    background-color: rgba(8, 31, 48, 0.6);
    /* border: 1px ridge #C0F5FA; */
    box-sizing: border-box;
    width: 57px;
    height: 57px;
    border-radius: 5px;
    transition: 1s all;
}

.modal-close-button:hover {
    cursor: pointer;
    background-color: #C0F5FA;
    color: rgba(8, 31, 48, 0.9);
}

.modal-close-button-text {
    font-size: 42px;
}

@media(max-width:500px) {
    .modal-container {
        width: 98%;
        height: 98%;
    }
    .modal-very-top {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding-left: 20px;
    }
    .modal-top {
        height: 50%;
    }
    .modal-info-project-name {
        font-size: 1.6em;
    }
    .modal-image-container {
        height: 100%;
        width: 99%;
        padding: 0px 2px 0px 2px;
    }
    .modal-info {
        height: auto;
        font-size: 1em;
    }
    .modal-info-project-link {
        width: auto;
    }
    .modal-info-project-link a {
        width: 100%;
    }
    .modal-close-button {
        bottom: 1em;
        right: 1em;
    }
}
    .portfolio {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        width: 100vw;
        height: 100vh;
        margin: 0px;
        padding: 5px 0px 50px 0px;
        overflow-x: hidden;
        overflow-y: hidden;
        background: transparent;
    }
    
    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    
    .portfolio-box {
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        width: 99%;
        min-width: 300px;
        max-width: 975px;
        height: auto;
        padding: 1.5em;
        /* margin: 10px; */
        /* margin: 0px 5px 0px 5px; */
        background-color: rgba(8, 31, 48, 0.6);
        border: 1px ridge #C0F5FA;
        border-radius: 40px;
        box-sizing: border-box;
        font-family: Helvetica;
        font-size: 1em;
        text-align: center;
        z-index: 500;
        -webkit-animation: 2s fadeIn;
                animation: 2s fadeIn;
        transition: 2s all;
    }
    
    .portfolios-box-background-image {
        position: absolute;
        background-image: url(/static/media/code.f2a1a633.jpg);
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        -webkit-filter: blur(2px);
                filter: blur(2px);
        opacity: 0.2;
    }
    
    .portfolio-heading {
        z-index: 1000;
        width: auto;
        color: #F9F9F9;
        font-family: "Helvetica";
        font-size: 48px;
        text-shadow: -2px 0px rgba(0, 0, 0, 0.3);
        padding-bottom: .25em;
        border-bottom: 1px solid rgb(85, 238, 255);
        text-align: center;
        margin: 0px 0px 15px 0px;
    }
    
    /* .portfolio-instructions {
        z-index: 1000;
        font-family: "Helvetica";
        padding: 0 5% 0 5%;
        font-size: 40px;
        text-shadow: -2px 0px rgba(0, 0, 0, 0.3);
        text-align: center;
        margin-top: .5em;
        margin-bottom: 0.5em;
        color: #F9F9F9;
    } */
    
    .portfolio-filter {
        z-index: 1000;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row;
                flex-flow: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        width: 100%;
        /* min-width: 500px; */
        height: 50px;
        margin-bottom: 15px;
    }
    
    .shadow {}
    
    .portfolio-filter-item {
        z-index: 1000;
        margin: auto 5px 0 5px;
        font-family: "Helvetica";
        font-size: 1.5em;
        font-weight: "400";
        color: rgb(85, 238, 255);
        line-height: 50px;
        padding: 0px 15px 0px 15px;
        transition: .5s all;
        border: 1px solid rgb(85, 238, 255);
        border-radius: .25em;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0px 0px 5px 1px rgb(106, 140, 179);
        /* -moz-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow: 3px 3px 5px 6px #ccc; */
    }
    
    .portfolio-filter-item:hover {
        background-color: rgb(85, 238, 255);
        border: 2px solid rgba(0, 0, 0, 0.8);
        cursor: pointer;
        -webkit-box-shadow: 0px 1px 1px 1px rgb(106, 158, 179);
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
    }
    
    .filter-active {
        background-color: rgb(85, 238, 255);
        border: 2px solid rgba(0, 0, 0, 0.8);
        font-weight: bold;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
    }
    
    .portfolio-projects-wrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row;
                flex-flow: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        width: 912px;
        height: 604px;
        box-sizing: content-box
    }
    
    .portfolio-project {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: column;
                flex-flow: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        position: relative;
        width: 300px;
        height: 300px;
        box-sizing: border-box;
        margin-left: 1px;
        margin-right: 1px;
        margin-top: 1px;
        margin-bottom: 1px;
        border-radius: 10px;
    }
    
    .portfolio-project-image {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 300px;
        height: 300px;
    }
    
    .portfolio-project-info {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: column;
                flex-flow: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: center;
                align-items: center;
        position: absolute;
        z-index: 2000;
        top: 0;
        left: 0;
        width: 300px;
        height: 300px;
        opacity: 0;
        background-color: transparent;
        box-sizing: border-box;
        transition: .25s all;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.72);
    }
    
    .portfolio-project-info:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
        /* color: rgb(85, 238, 255); */
    }
    
    .portfolio-project-info-top {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: column;
                flex-flow: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        height: 200px;
        padding: .25em;
        text-align: center;
        color: white;
    }
    
    .portfolio-project-name {
        font-size: 32px;
        /* color: #232323; */
        color: white;
        font-family: "Helvetica";
        margin: 0px;
        padding: 40px 0px 0px 5px;
    }
    
    .portfolio-project-technologies {
        font-size: 24px;
        padding: .25em;
        /* color: #232323; */
        color: white;
        font-family: "Helvetica";
    }
    
    .portfolio-project-info-bottom {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: column;
                flex-flow: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        height: 100px;
    }
    /* .portfolio-project-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 250px;
        padding: .25em;
        border: 1px solid #232323;
        border-radius: 5px;
        box-sizing: border-box;
        transition: .25s all;
        background-color: white;
    }
    
    .portfolio-project-button:hover {
        cursor: pointer;
        background-color: rgb(85, 238, 255);
        color: #232323;
        font-weight: bold;
        font-size: 1.1em;
    }
    
    .portfolio-project-button-text {
        font-size: 20px;
        height: 50px;
        line-height: 50px;
        width: 100%;
        text-align: center;
    } */
    
    .portfolio-project-button {
        height: 50px;
        width: 250px;
        border: 1px solid rgb(85, 238, 255);
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        transition: 1s all;
        border-radius: 25px;
        background-color: rgba(0, 0, 0, 0.4);
    }
    
    .portfolio-project-button:hover {
        background-color: aqua;
        cursor: pointer;
    }
    
    .portfolio-project-button-text {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        height: 50px;
        width: 100%;
        text-decoration: none;
        font-size: 1.5em;
        /* color: rgba(255, 255, 255, 1); */
        color: rgb(85, 238, 255);
    }
    
    .portfolio-project-button-text:hover {
        color: #232323;
        font-weight: 600;
    }
    
    @media(max-width: 950px) {
        .portfolio {
            height: auto;
        }
        .portfolio-projects-wrapper {
            position: relative;
            width: 620px;
            height: auto;
        }
        .portfolio-instructions {
            font-size: 32px;
        }
    }
    
    @media(max-width: 650px) {
        .portfolio-projects-wrapper {
            width: 300px;
            margin: auto
        }
    }
    
    @media(max-width: 500px) {
        .portfolio-heading {
            font-size: 2em;
        }
    }
    
    @media(max-width: 450px) {
        .portfolio-instructions {
            font-size: 1.5em;
        }
    }
        .form-wrapper {
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
                    justify-content: center;
            -webkit-align-items: center;
                    align-items: center;
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 5000;
            top: 0;
            left: 0;
            transition: 1s all;
            opacity: 1;
        }
        
        .form-show {
            opacity: 1;
            pointer-events: none;
        }
        
        .start-welcome-box {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            width: auto;
            min-width: 300px;
            height: auto;
            padding: 0.5em;
            background-color: rgba(8, 31, 48, 0.6);
            border: 1px ridge #C0F5FA;
            border-radius: 40px;
            box-sizing: border-box;
            font-family: Helvetica;
            font-size: 72px;
            text-align: center;
            margin: auto;
            z-index: 500;
            -webkit-animation: 2s fadeIn;
                    animation: 2s fadeIn;
            transition: 2s all;
        }
        
        .form-background {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: column;
                    flex-flow: column;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 6000;
            height: 100%;
            width: 100%;
            margin: 0px;
            padding: 0px;
            background-color: rgb(42, 42, 42, 0.5);
        }
        
        .form-container {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: column;
                    flex-flow: column;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            width: auto;
            height: auto;
            background-color: rgba(8, 31, 48, 0.95);
            border: 1px ridge #C0F5FA;
            position: relative;
            overflow-y: hidden;
            padding: 25px;
            z-index: 7000;
            margin: auto;
            border-radius: 5px;
        }
        
        .form-container-heading {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            color: #C0F5FA;
            font-size: 30px;
            margin: 5px 0px 5px 0px;
        }
        
        form {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: column;
                    flex-flow: column;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            height: auto;
        }
        
        #name,
        #email,
        #city,
        #country {
            font-family: "Helvetica";
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
                    justify-content: center;
            -webkit-align-items: center;
                    align-items: center;
            border: 1px ridge #022F40;
            box-sizing: border-box;
            width: 330px;
            height: 40px;
            margin: 10px auto 0px auto;
            padding-left: 1em;
            background-color: #F5F5F5;
            border-radius: 5px;
            transition: 1s all;
        }
        
        #message {
            font-family: "Helvetica";
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
                    justify-content: center;
            -webkit-align-items: center;
                    align-items: center;
            border: 1px ridge #022F40;
            box-sizing: border-box;
            width: 330px;
            height: 300px;
            margin: 1em auto 0px auto;
            padding: 1em;
            background-color: #F5F5F5;
            border-radius: 5px;
            transition: 1s all;
        }
        
        #submit {
            font-family: "Helvetica";
            font-size: 1.5em;
            color: #022F40;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
                    justify-content: center;
            -webkit-align-items: center;
                    align-items: center;
            border: 1px solid black;
            box-sizing: border-box;
            width: 330px;
            height: 40px;
            margin: 10px auto 0px auto;
            padding-left: 1em;
            background-color: #C0F5FA;
            border-radius: 5px;
            transition: .25s all;
        }
        
        #submit:hover {
            background-color: #75F4F4;
            font-size: 1.5em;
            cursor: pointer;
        }
        
        .form-close-button {
            position: absolute;
            font-family: "Helvetica";
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
                    justify-content: center;
            -webkit-align-items: center;
                    align-items: center;
            top: 1px;
            right: 1px;
            background-color: transparent;
            background-color: rgba(8, 31, 48, 0.6);
            box-sizing: border-box;
            width: 57px;
            height: 57px;
            border-radius: 5px;
            transition: 1s all;
            cursor: pointer;
            color: #C0F5FA;
        }
        
        .form-close-button:hover {
            cursor: pointer;
            background-color: #C0F5FA;
            color: rgba(8, 31, 48, 1);
        }
        
        .form-close-button-text {
            font-size: 42px;
        }
        
        @media(min-width:951px) {
            #name,
            #email,
            #city,
            #country,
            #message {
                width: 600px;
                font-size: 1.3em;
            }
        }
        
        @media(max-width:950px) {
            #name,
            #email,
            #city,
            #country,
            #message {
                width: 500px;
            }
        }
        
        @media(max-width: 600px) {
            .form-container {
                width: 99%;
                height: 90%;
            }
            .form-container h2 {
                margin: 5px 0px 5px 0px;
            }
            #name,
            #email,
            #city,
            #country,
            #message {
                width: 330px;
            }
            #submit {
                margin-top: 12px;
            }
            .form-info {
                height: auto;
                font-size: .75em;
            }
        }
.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.map-container {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
    opacity: 0.91;
    border-radius: 1em;
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    background-color: #0e1626;
}

.top-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 2em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-color: #0e1626;
}
.contact {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    margin: 0px;
    overflow-x: hidden;
}

.contact-box {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 99%;
    max-width: 975px;
    height: auto;
    padding: 1em 0px 1em 0px;
    background-color: rgba(8, 31, 48, 0.6);
    border: 1px ridge #C0F5FA;
    border-radius: 40px;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 1em;
    text-align: center;
    z-index: 500;
    -webkit-animation: 2s fadeIn;
            animation: 2s fadeIn;
    transition: 2s all;
    overflow: hidden;
}

.contact-form-container {
    z-index: 1000;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    box-sizing: border-box;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.contact-form-heading {
    width: auto;
    max-width: 90%;
    /* color: rgba(255, 255, 255, 1); */
    color: #F9F9F9;
    font-family: "Helvetica";
    /* padding-bottom: .25em; */
    padding-top: .25em;
    font-size: 2.1em;
    border-bottom: 1px solid rgb(85, 238, 255);
    /* border-top: 1px solid #F9F9F9; */
    text-align: center;
    text-shadow: 2px 0px rgba(0, 0, 0, 0.3);
}

.contact-form-instructions {
    font-family: "Helvetica";
    font-size: 2em;
    width: 80%;
    /* color: rgba(255, 255, 255, 1); */
    color: #F9F9F9;
    text-align: center;
    text-shadow: 2px 0px rgba(0, 0, 0, 0.3);
    margin: 0.5em 0 0.5em 0;
}

.contact-button {
    height: 50px;
    width: 250px;
    border: 1px solid rgb(85, 238, 255);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    transition: 1s all;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.4);
}

.contact-button:hover {
    background-color: aqua;
    cursor: pointer;
}

.contact-button-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 50px;
    width: 100%;
    text-decoration: none;
    font-size: 1.5em;
    /* color: rgba(255, 255, 255, 1); */
    color: rgb(85, 238, 255);
}

.contact-button-text:hover {
    color: #232323;
    font-weight: 600;
}

.icon-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    width: 300px;
    height: 50px;
    margin-top: 50px;
}

.icon-container:hover {
    cursor: pointer
}

.icon-container a {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    text-decoration: none;
    /* color: rgba(255, 255, 255, 1); */
    color: #F9F9F9;
    font-size: 1em;
    font-weight: bold;
}

.icon-text {
    margin-top: 5px;
}

.bottom-strip {
    position: relative;
    left: 0;
    bottom: 30px;
    width: 100%;
    height: 30px;
    background-color: rgba(13, 2, 33, 1);
}

@media(max-width: 700px) {
    .contact-form-container {
        width: 100%;
    }
    .contact-form-instructions {
        font-size: 1.5em;
    }
    .icon-container {
        margin-top: 30px;
    }
}

@media(max-width: 500px) {
    .contact-heading {
        font-size: 2em;
    }
}

@media(max-width: 450px) {
    .contact-instructions {
        font-size: 1.5em;
    }
}

@media (max-width: 321px) {
    .contact-form-instructions {
        font-size: 1.25em;
    }
    .icon-container {
        margin-top: 40px;
    }
}


/*  */

.contact-welcome-box-text-special {
    color: #75F4F4;
}
#footer {
    position: relative;
    height: 50px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10px 10px 10px 10px;
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    color: #1abc9c;
}

#footer span {
    font-size: 20px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.footer-box-background-image {
    position: absolute;
    background-image: url(/static/media/code.f2a1a633.jpg);
    background-size: cover;
    width: 100%;
    height: 100px;
    -webkit-filter: blur(10px);
            filter: blur(10px);
    opacity: 0.3;
}
   #slide {
       position: absolute;
       right: -500px;
       top: 0px;
       width: 100%;
       height: 100px;
       /* background: blue; */
       -webkit-animation: slide 0.5s forwards;
       -webkit-animation-delay: 2s;
       animation: slide 0.5s forwards;
       -webkit-animation-delay: 0.05s;
               animation-delay: 0.05s;
   }
   
   .slide-out {
       right: -500px;
       top: 0px;
       -webkit-animation: slide 0.5s forwards;
       -webkit-animation-delay: 2s;
       animation: slide 0.5s forwards;
       -webkit-animation-delay: 0.05s;
               animation-delay: 0.05s;
   }
   
   .sideDrawer {
       display: -webkit-flex;
       display: flex;
       -webkit-flex-flow: column;
               flex-flow: column;
       -webkit-justify-content: flex-start;
               justify-content: flex-start;
       -webkit-align-items: flex-end;
               align-items: flex-end;
       position: absolute;
       right: 0;
       top: 0;
       height: 100vh;
       min-width: 250px;
       width: 250px;
       max-width: 500px;
       /* background-color: #022F40; */
       /* background-color: #2274A5; */
       /* background-color: rgba(0,0,0,0.4); */
       background-color: rgba(8, 31, 48, 0.94);
       /* background: radial-gradient( circle at top right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 60%, rgba(0, 0, 0, 0) 61%); */
       font-family: "Helvetica";
       border-left: 1px ridge #75F4F4;
   }
   
   .sideDrawer:hover {
       cursor: default;
   }
   
   .sideDrawerHeader {
       display: -webkit-flex;
       display: flex;
       -webkit-flex-flow: column;
               flex-flow: column;
       -webkit-justify-content: center;
               justify-content: center;
       -webkit-align-items: flex-end;
               align-items: flex-end;
       padding-top: 50px;
       width: 100%;
       height: 100px;
   }
   
   .sideDrawerHeader p {
       padding-right: 30px;
       color: rgba(255, 255, 255, 0.9);
       /* color: rgba(117, 174, 220, 1); */
       /* color: #18426b; */
       font-family: "Helvetica";
       font-weight: bold;
       font-size: 32px;
       margin-bottom: 0px;
   }
   
   .sideDrawer-link {
       /* color: rgba(0, 0, 0, 0.87); */
       /* color: #18426b; */
       /* color: #5299D3; */
       color: rgba(117, 174, 220, 1);
       /* color: rgba(0, 0, 0, 0, 0.7); */
       font-family: "Helvetica";
       /* color: #18426b; */
       font-size: 20px;
       display: -webkit-flex;
       display: flex;
       -webkit-justify-content: flex-end;
               justify-content: flex-end;
       -webkit-align-items: center;
               align-items: center;
       width: auto;
       height: 36px;
       margin: 0px 0 0 0;
       padding: 5px 30px 5px 5px;
   }
   
   .sideDrawer-link:hover {
       font-weight: bold;
       color: #18426b;
       background-color: rgba(49, 225, 231, 0.26);
       background: linear-gradient( to left, rgba(0, 255, 255, 0.959), rgba(49, 225, 231, 0.26) 80%, transparent 100%);
   }
   
   .sideDrawer-icon-container {
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: column;
               flex-direction: column;
       -webkit-align-items: center;
               align-items: center;
       -webkit-justify-content: flex-end;
               justify-content: flex-end;
       /* flex-wrap: wrap; */
       width: 100%;
       height: auto;
       margin-top: 0px;
       padding: 0px 0px 0px 0px;
       /* border-top: 1px solid lightgrey; */
       /* background-color: #18426b; */
       /* background: linear-gradient( to left, #18426b, #18426b 40%, transparent 100%); */
   }
   
   .sideDrawer-icon-container:hover {
       /* cursor: pointer */
   }
   
   .sideDrawer-icon-container a i {
       font-size: 2em;
       /* display: flex;
       flex-direction: column; */
       /* color: #33A9DC; */
       /* color: #5299D3; */
       /* color: #18426b; */
       color: rgba(117, 174, 220, 1);
       padding: 8px;
       border-radius: 10px;
       width: 33px;
       height: 33px;
       text-align: center;
       margin-top: 5px;
       text-decoration: none;
   }
   
   .sideDrawer-icon-container a i:hover {
       font-size: 2em;
       color: #18426b;
       background: linear-gradient(to left, rgba(0, 255, 255, 0.959), rgba(0, 255, 255, 0.959) 66%, transparent);
   }
   
   .nav-item-link {
       color: rgba(0, 0, 0, 0.87);
       width: 90%;
       margin: 5px 10px 5px 5px;
       cursor: pointer;
   }
   
   .icon-container-row {
       display: -webkit-flex;
       display: flex;
       -webkit-flex-flow: row;
               flex-flow: row;
       -webkit-align-items: center;
               align-items: center;
       -webkit-justify-content: space-evenly;
               justify-content: space-evenly;
       width: 100%;
   }
   
   .linkWrapper {
       width: 70px;
   }
   
   .linkWrapper a {
       display: -webkit-flex;
       display: flex;
       -webkit-flex-direction: column;
               flex-direction: column;
       -webkit-align-items: center;
               align-items: center;
       -webkit-justify-content: center;
               justify-content: center;
       text-decoration: none;
       color: rgba(117, 174, 220, 1);
       margin: 0px 5px 0px 5px;
   }
   
   .icon-text {
       margin: 0;
       font-weight: bold;
   }
   
   .nav-item-link:hover {
       color: rgba(0, 0, 0, 0.87);
   }
   
   .active {
       font-weight: bold;
       /* box-shadow: -2px 2px 2px 1px #C0F5FA; */
       /* -webkit-box-shadow: -3px 3px 1px 0px #C0F5FA; */
       /* -moz-box-shadow: 3px 3px 5px 6px #C0F5FA;
       box-shadow: 3px 3px 5px 6px #C0F5FA; */
   }
   
   .active p {
       font-weight: bold;
       color: #75F4F4;
       /* color: #18426b; */
       /* color: rgba(0, 0, 0, 0.87); */
       /* text-shadow: 1px 1px #C0F5FA; */
   }
   
   .sideDrawerShadow {
       /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
       /* Firefox 3.5 - 3.6 */
       box-shadow: 3px 3px 5px 6px #C0F5FA;
       /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
   }
   /* Slide In */
   
   @-webkit-keyframes slide {
       100% {
           right: 0;
           top: 0;
       }
   }
   
   @keyframes slide {
       100% {
           right: 0;
           top: 0;
       }
   }
.navBurger {
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 5000;
    margin: 0px;
    padding-top: 15px;
    padding-right: 15px;
}

#navBurger-icon {
    margin: 0px;
    padding: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 2.5em;
    color: rgba(2, 0, 36, 1);
}

.scrollingNavBurgerIcon {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    /* background-color: #3895D3; */
    background-color: #75F4F4;
     border-radius: 50%;
}
.parallax {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    margin: 0px;
    overflow: hidden;
    position: fixed;
    background-color: #252934;
    background-image: url(/static/media/programming.bf78a4c7.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.particle-canvas {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    -webkit-animation: 5s fadeIn;
            animation: 5s fadeIn;
    cursor: crosshair;
    opacity: 0.7;
}

.scrollInstruction {
    position: absolute;
    z-index: 3000;
    right: 0;
    margin-right: 15px;
    padding: 5px;
    width: 50px;
    height: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 40px;
    font-size: 1.5em;
}

.scrollInstruction i {
    color: rgb(85, 238, 255);
    z-index: 4000;
}

.scrollInstruction i:hover {
    cursor: pointer;
}


/* Dimension Changes for different devices */

@media (max-width: 1200px) {
    .scrollInstruction {
        display: none;
    }
}

@media (max-width: 1330px) {
    .chart-wrapper {
        height: 300px;
    }
}
.App {
    margin: 0px;
    padding: 0px;
    top: 0;
    left: 0;
    font-family: "Helvetica";
    overflow: hidden;
    background-color: rgba(2, 0, 36, 1);
}

 ::-webkit-scrollbar {
    height: 0px;
    /* remove scrollbar space */
    width: 0px;
    background: transparent;
    /* background-color: #aaa; */
    /* optional: just make scrollbar invisible */
}
