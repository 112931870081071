.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.modal-background {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6000;
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: rgb(42, 42, 42, 0.5);
}

.model-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
    min-width: 300px;
    max-width: 975px;
    height: auto;
    padding: 1.5em;
    /* margin: 10px; */
    /* margin: 0px 5px 0px 5px; */
    background-color: rgba(8, 31, 48, 0.6);
    border: 1px ridge #C0F5FA;
    border-radius: 40px;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 1em;
    text-align: center;
    z-index: 500;
    animation: 2s fadeIn;
    transition: 2s all;
}

.modal-container {
    width: 650px;
    height: 700px;
    /* border: 1px ridge #022F40; */
    border: 1px ridge #C0F5FA;
    /* background-color: #2274A5; */
    background-color: rgba(8, 31, 48, 0.9);
    color: #E7DFC6;
    position: relative;
    border-radius: 5px;
    overflow-y: hidden;
    padding: .25em;
    z-index: 7000;
    margin: auto;
}

.modal-very-top {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 100%;
}

.modal-top {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
}

.modal-image-container {
    display: flex;
    justify-content: center;
    align-items: flex-bottom;
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: rgba(8, 31, 48, 0.5);
    overflow-y: hidden;
    border-radius: 5px;
    padding: 0px 00px 0px 0px;
}


/* Carousel */

.slider-slide img {
    height: auto;
    max-height: 350px;
    width: auto!important;
    max-width: 100%!important;
    margin: auto;
}

.slider-control-bottomcenter {
    display: none;
}

.slider button {
    font-size: 1em;
    font-weight: bold;
    margin: 0px 5px 0px 5px;
}


/**/

.modal-info {
    padding: 0px 20px 0px 20px;
    font-size: 20px;
}

.modal-info-project-name {
    font-family: "Helvetica";
    font-size: 1.9em;
}

.modal-info-project-summary {
    font-family: "Helvetica"
}

.modal-info-project-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    box-sizing: border-box;
    width: 330px;
    font-size: 20px;
    height: 40px;
    margin: auto;
    background-color: #F5F5F5;
    border-radius: 5px;
    transition: 1s all;
}

.modal-info-project-link a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #022F40;
    height: 40px;
    width: 330px;
    text-align: center;
}

.modal-info-project-link:hover {
    cursor: pointer;
    background-color: aqua
}

.modal-close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 20px;
    border: 1px solid black;
    color: #C0F5FA;
    background-color: transparent;
    background-color: rgba(8, 31, 48, 0.6);
    /* border: 1px ridge #C0F5FA; */
    box-sizing: border-box;
    width: 57px;
    height: 57px;
    border-radius: 5px;
    transition: 1s all;
}

.modal-close-button:hover {
    cursor: pointer;
    background-color: #C0F5FA;
    color: rgba(8, 31, 48, 0.9);
}

.modal-close-button-text {
    font-size: 42px;
}

@media(max-width:500px) {
    .modal-container {
        width: 98%;
        height: 98%;
    }
    .modal-very-top {
        justify-content: flex-start;
        padding-left: 20px;
    }
    .modal-top {
        height: 50%;
    }
    .modal-info-project-name {
        font-size: 1.6em;
    }
    .modal-image-container {
        height: 100%;
        width: 99%;
        padding: 0px 2px 0px 2px;
    }
    .modal-info {
        height: auto;
        font-size: 1em;
    }
    .modal-info-project-link {
        width: auto;
    }
    .modal-info-project-link a {
        width: 100%;
    }
    .modal-close-button {
        bottom: 1em;
        right: 1em;
    }
}